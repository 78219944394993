<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <span>
          <b-img
            :src="require('@/assets/images/erp/icono.png')"
            alt="logo"
            height="50"
          />
        </span>
        <h2 class="brand-text text-primary ml-1 mt-1">
          MiPanel
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="require('@/assets/images/erp/travel.png')"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img
              fluid
              :src="require('@/assets/images/erp/logo-mipanel.png')"
              width="350"
            />
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a Visita San Martín
          </b-card-title>
          <b-card-text class="mb-2">
            Por favor ingrese sus credenciales para comenzar.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label-for="usuario"
                label="Usuario"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Usuario"
                  rules="required"
                >
                  <b-form-input
                    id="usuario"
                    v-model="userEmail"
                    name="login-usuario"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Usuario"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Contraseña</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password-v1' }">
                    <small>Olvidaste tu contraseña?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Recordarme
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Ingresar
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'page-auth-register-v2' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* import VuexyLogo from '@core/layouts/components/Logo.vue' */
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  // BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser, getAbilityByRol } from '@/auth/utils'
// import SocketioService from '../../services/socket'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    /*  VuexyLogo, */
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      token: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    async login() {
      /*  useJwt.setToken('oakoakoa')
      console.log(getAbilityByRol('Empresa'))
      this.$ability.update([{ action: 'manage', subject: 'all' }])
      this.$router
        .push(getHomeRouteForLoggedInUser('Empresa'))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Bienvenido ',
              icon: 'CoffeeIcon',
              variant: 'success',
              text:
                'Has iniciado sesión con éxito como . Ahora puedes empezar a trabajar!',
            },
          })
        })
        .catch(error => {
          console.log(error)
        }) */
      await store
        .dispatch('auth/AUTH_CREATE_TOKEN_PI', {
          password: '123456xx',
          username: 'machine@visitasanmartin.pe',
        })
        .then(async due => {
          localStorage.setItem('tokenPI', due.token)
        })
        .catch(error => {
          console.log(error)
        })
      await store
        .dispatch('auth/AUTH_CREATE_TOKEN', {
          username: this.userEmail,
          password: this.password,
        })
        .then(async response => {
          if (response) {
            this.token = response.token
            const { user } = response
            useJwt.setToken(response.token)
            useJwt.setRefreshToken(response.token)
            user.ability = getAbilityByRol(user.perfil.nombre)
            localStorage.setItem('userData', JSON.stringify(user))
            localStorage.setItem('userProfile', JSON.stringify(user.perfil))
            this.$ability.update([{ action: 'manage', subject: 'all' }])
            // SocketioService.setupSocketConnection(response.user.id)
            await store
              .dispatch('panel/EMPRESA_FIND_BY_RUC', {
                ruc: this.userEmail,
              })
              .then(directorio => {
                localStorage.setItem('empresa', JSON.stringify(directorio[0]))
              })
              .catch(error => {
                console.log(error)
              })
            this.$router
              .push(getHomeRouteForLoggedInUser(user.perfil.nombre))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Bienvenido ${user.nombreCompleto}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Has iniciado sesión con éxito como ${user.perfil.nombre}. Ahora puedes empezar a trabajar!`,
                  },
                })
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Acceso no Autorizado',
                icon: 'LockIcon',
                variant: 'danger',
                text:
                  'Lo sentimos no tienes ningún perfil asignado para acceder a la plataforma.',
              },
            })
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.errorMessage
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ha ocurrido un error',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: errorMessage,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
